import { Observable } from 'rxjs';

type Color = 'default' | 'alert' | 'neutral' | 'neutral-light' | 'neutral-dark';
type Variant = 'primary' | 'secondary';

export interface ConfirmDialogData {
  dialogTitle?: string;
  dialogText?: string;
  confirmText?: string;
  cancelText?: string;
  icon?: string;
  confirmButtonColor?: Color;
  confirmButtonType?: Variant;
  confirmAction?: () => void;
  cancelAction?: () => void;
}

export abstract class ConfirmDialog {
  abstract open(event: ConfirmDialogData): Observable<any>;
}
