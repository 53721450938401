<div
  class="flat-table-container data-overview"
  [class.uses-pagination]="usesPagination"
  [class.show-pagination]="paging"
  [ngClass]="[
    'density-' + format?.flatTable.general.density,
    'sizing-style-' + sizingStyle,
    tableFinishedLoading ? 'table-loaded' : 'table-loading'
  ]"
>
  <div class="flat-table">
    <div
      class="flat-table-renderer ag-theme-mode"
      [ngClass]="[colorSchemeCssClass()]"
      [class.with-row-gridlines]="flatTableFormat?.general.rowGridlines"
      [class.with-col-gridlines]="flatTableFormat?.general.colGridlines"
      [class.text-wrapped]="textWrappingCounter.current > 0"
    ></div>
    <div
      capraTooltip
      [tooltipContent]="sortSettingsTooltipContent"
      [tooltipContentIsHtml]="true"
      class="sort-settings-tooltip"
    ></div>
  </div>
  <div *ngIf="paging" class="table-pagination-container show-go-to show-pager-info">
    <nav *ngIf="paging" aria-label="Pagination" class="table-pagination-nav">
      <ul class="table-pagination">
        <li class="first">
          <button
            class="btn btn-link btn-hide-text"
            [disabled]="paging.offset === 0"
            (click)="setOffset(0)"
            aria-label="First Page"
          >
            <capra-icon icon="icon-pagination-first" title="First page"></capra-icon>
          </button>
        </li>
        <li class="prev">
          <button
            class="btn btn-link btn-hide-text"
            [disabled]="!paging.hasPrev"
            (click)="setOffset(prevOffset)"
            aria-label="Previous Page"
          >
            <capra-icon icon="icon-chevron-left" title="Previous page"></capra-icon>
          </button>
        </li>

        <li>
          <div class="go-to-page">
            <label
              ><span class="hide-6">Page</span>
              <input
                type="number"
                class="form-control form-control-sm"
                [formControl]="pagingControl"
                [value]="pagingControl.value"
                (blur)="handlePaginationBlur($event.target.valueAsNumber)"
                (keydown)="handlePaginationKeydown($event.keyCode, $event.target.valueAsNumber)"
                min="1"
                [max]="lastPage"
              />
              <span class="hide-6">of {{ lastPage | number }}</span>
            </label>
          </div>
        </li>

        <li class="next">
          <button
            class="btn btn-link"
            [disabled]="!paging.hasNext"
            (click)="setOffset(nextOffset)"
            aria-label="Next page"
          >
            <capra-icon icon="icon-chevron-right" title="Next page"></capra-icon>
          </button>
        </li>
        <li class="last">
          <button
            class="btn btn-link"
            [disabled]="!paging.hasNext"
            (click)="setOffset(lastOffset)"
            aria-label="Last Page"
          >
            <capra-icon icon="icon-pagination-last" title="Last page"></capra-icon>
          </button>
        </li>
      </ul>
    </nav>
    <div class="metadata-container hide-5">
      <div
        class="row-limit-container"
        *ngIf="paging && paging.total > 0 && this.allowCustomPageSize && customPageSizeEnabled"
      >
        <label for="row-limit-input">Row limit per page: </label>
        <input
          id="row-limit-input"
          type="number"
          class="form-control form-control-sm"
          [formControl]="pageSizeControl"
          [value]="pageSizeControl.value"
          (blur)="handlePageChange(true, 0)"
          (keydown)="handlePageChange(false, $event.keyCode)"
          min="1"
          [max]="1000"
        />
      </div>
      <div *ngIf="paging && paging.total === 0" class="metadata"><label>Showing</label> 0 rows</div>
      <div *ngIf="paging && paging.total > 0" class="metadata">
        <label>Showing rows </label>
        <span>{{ paging.offset + 1 | number }}-{{ pageRangeEnd | number }} of {{ paging.total | number }}</span>
      </div>
      <div *ngIf="columns && !hideColumnsCount" class="metadata hide-4"><label>Columns</label>{{ columns.length }}</div>
      <div *ngIf="size" class="metadata hide-3"><label>Size</label>{{ size }}</div>
      <div *ngIf="lastSuccessfulRunAt" class="metadata last-run hide-2">
        <label>{{ lastSuccessfulLabel }}</label>
        <span
          *ngIf="isFallbackData; else happyPath"
          capraTooltip
          [tooltipContent]="fallbackDataTooltip"
          [tooltipContentIsHtml]="true"
          tooltipSize="small"
          data-test="fallback-data-warning-icon"
          class="fallback-data"
        >
          <capra-icon icon="icon-warning"></capra-icon>
          <mode-time-ago [date]="lastSuccessfulRunAt"></mode-time-ago>
        </span>
        <ng-template #happyPath>
          <mode-time-ago [date]="lastSuccessfulRunAt"></mode-time-ago>
        </ng-template>
      </div>
      <div
        *ngIf="executionTime"
        class="metadata hide-1"
        capraTooltip
        [tooltipContent]="executionInfoTooltip"
        [tooltipContentIsHtml]="true"
      >
        <label>Executed in</label>{{ executionTime }}
      </div>
    </div>
  </div>
</div>
<div
  [class.hide-legend]="!showLegend"
  class="flat-table-legends-container"
  [style.width]="legendsContainerInfo.width + 'px'"
  [style.height]="legendsContainerInfo.height + 'px'"
  [style.padding-left]="legendsContainerInfo.padding.left + 'px'"
  [style.padding-right]="legendsContainerInfo.padding.right + 'px'"
  [style.padding-top]="legendsContainerInfo.padding.top + 'px'"
  [style.padding-bottom]="legendsContainerInfo.padding.bottom + 'px'"
>
  <div
    class="flat-table-legend-box"
    [ngClass]="['flat-table-legend-box-' + legendInfo.legendInst.type(), 'flat-table-legend-box-id-' + legendInfo.id]"
    *ngFor="let legendInfo of legendInfoList; trackBy: legendTrackBy"
    [style.width]="'100%'"
    [style.height]="legendInfo.height + 'px'"
  >
    <mode-flat-table-gradient-legend
      [legendInfo]="legendInfo"
      [legendColor]="legendColor"
    ></mode-flat-table-gradient-legend>
  </div>
</div>
<mode-loader
  class="conditional-formatting-loader"
  *ngIf="conditionalFormattingEnabled && conditionalFormattingLoading"
></mode-loader>
