import { ReportTypes } from '../..';
export enum ReportType {
  DatasetReport = 'DatasetReport',
  Report = 'Report',
}

export type ReportCreationMenuItem = {
  href: string;
  title: string;
  subtitle: string;
  icon: string;
  class: string;
  testAttribute: string;
  trackClickData: any;
};

export interface Report {
  token: string;
  name: string;
  description: string;
  layout: string;
  last_run_at: string;
  hasSuccessfulReportRun: boolean;
  drillAnywhereEnabled: boolean;
  manualRunDisabled: boolean;
  iFrameSrc: string | undefined;
  isViewable: boolean;
  shared: boolean;
  theme: ReportTypes.ReportTheme;
  thoughtspotPublishedAt: string | null;
  creatorName: string | undefined;
  spaceDefaultAccess: string | null | undefined;
  spaceName: string | undefined;
  spaceType: string | undefined;
  spaceToken: string | undefined;
  orgName: string;
  // TODO: The rest of the fields
}

export interface ReportRunFormFieldOptions {
  name: string;
  options: [string, string | number][];
  optionsError?: { message: string };
  sourceSize: number;
}

export const enum ReportRunFailCause {
  Unknown = 'unknown',
  TooManyQueries = 'too_many_queries',
  QueryTooLarge = 'query_too_large',
}
