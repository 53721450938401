/* eslint-disable no-prototype-builtins */
// Helpers for reading the meta tags
let metaCache: { [name: string]: string | null } = {};

export const enum MetaTags {
  AccountEntryPath = 'account-entry-path',
  AlgoliaId = 'algolia-id',
  AlgoliaKey = 'algolia-key',
  AlgoliaSecurityTags = 'algolia-security-tags',
  AlgoliaUserToken = 'algolia-user-token',
  CaptureMode = 'capture-mode',
  CsrfParam = 'csrf-param',
  CsrfToken = 'csrf-token',
  CurrentUsername = 'current-username',
  DebugJS = 'debugjs',
  EditorShowcase = 'editor-showcase',
  FbAppId = 'fb-app-id',
  FlamingoHost = 'flamingo-host',
  HomeView = 'home-view',
  RlsIsUnlinkable = 'rls-is-unlinkable',
  LaunchDarklyClientId = 'launch-darkly-client-id',
  LaunchDarklyEnv = 'launch-darkly-env',
  OwnerUsername = 'owner-username',
  PerformanceReport = 'performance-report',
  PusherKey = 'pusher-key',
  ReportOwnerUsername = 'report-owner-username',
  ReportRunEntryPath = 'report-run-entry-path',
  ReportRunToken = 'report-run-token',
  ReportEntryPath = 'report-entry-path',
  SearchSettings = 'search-settings',
  SecretToken = 'secret-token',
  TrialBanner = 'trial-banner',
  WebappRevision = 'webapp-revision',
  WorkspaceIsIntegrated = 'workspace-is-integrated',
}

// To make it easier to test
export interface Queryable {
  querySelector: HTMLDocument['querySelector'];
}

export function clearDOM(): void {
  document.body.innerHTML = `<html><head></head><body></body></html>`;
}

export function makeDOMWithMetas(contentAttrs: Array<{ name: MetaTags; content?: string }>) {
  const contentElements: string[] = contentAttrs.map(({ name, content }) => {
    const contentAttribute = content == null ? '' : `content="${content}"`;
    return `<meta name="${name}" ${contentAttribute} \\>`;
  });
  document.body.innerHTML = `<html><head>${contentElements.join('')}</head><body></body></html>`;
}

export function makeDOMWithMeta(name: string, content?: string) {
  const contentAttribute = content == null ? '' : `content="${content}"`;
  document.body.innerHTML = `<html><head><meta name="${name}" ${contentAttribute} \\></head><body></body></html>`;
}

export function setMetaContent(queryable: Queryable, name: string, value: string) {
  const meta: HTMLMetaElement | null = queryable.querySelector(`meta[name=${name}]`);

  if (meta) {
    meta.setAttribute('content', (meta.content = value));
  }
}

export function getMetaContent(queryable: Queryable, name: string) {
  const meta: HTMLMetaElement | null = queryable.querySelector(`meta[name=${name}]`);

  let value: string | null = null;
  if (meta) {
    value = meta.content;
  }

  return value === '' ? null : value;
}

function hasKey(key: MetaTags) {
  return metaCache.hasOwnProperty(key);
}

function makeGetter(key: MetaTags) {
  return function (queryable: Queryable, bypassCache = false) {
    /**
     * Added case for modules that query these values synchronously in the
     * top level scope. A default of `false` allows us to maintain parity
     * with existing call sites while accommodating new cases that have been
     * operating without a cache. If we later decide to let those call sites
     * cache as well, just remove this case and drop the second param
     */
    if (bypassCache) {
      return getMetaContent(queryable, key);
    }

    if (!hasKey(key)) {
      metaCache[key] = getMetaContent(queryable, key);
    }

    return metaCache[key];
  };
}

export const getAccountEntryPath = makeGetter(MetaTags.AccountEntryPath);
export const getAlgoliaId = makeGetter(MetaTags.AlgoliaId);
export const getAlgoliaKey = makeGetter(MetaTags.AlgoliaKey);
export const getAlgoliaSecurityTags = makeGetter(MetaTags.AlgoliaSecurityTags);
export const getAlgoliaUserToken = makeGetter(MetaTags.AlgoliaUserToken);
export const getCsrfParam = makeGetter(MetaTags.CsrfParam);
export const getCsrfToken = makeGetter(MetaTags.CsrfToken);
export const getCurrentUsername = makeGetter(MetaTags.CurrentUsername);
export const getDebugJS = makeGetter(MetaTags.DebugJS);
export const getEditorShowcase = makeGetter(MetaTags.EditorShowcase);
export const getFbAppId = makeGetter(MetaTags.FbAppId);
export const getFlamingoHost = makeGetter(MetaTags.FlamingoHost);
export const getHomeView = makeGetter(MetaTags.HomeView);
export const getRlsIsUnlinkable = makeGetter(MetaTags.RlsIsUnlinkable);
export const getLaunchDarklyClientId = makeGetter(MetaTags.LaunchDarklyClientId);
export const getLaunchDarklyEnv = makeGetter(MetaTags.LaunchDarklyEnv);
export const getOwnerUsername = makeGetter(MetaTags.OwnerUsername);
export const getPusherKey = makeGetter(MetaTags.PusherKey);
export const getReportOwnerUsername = makeGetter(MetaTags.ReportOwnerUsername);
export const getReportRunEntryPath = makeGetter(MetaTags.ReportRunEntryPath);
export const getReportRunToken = makeGetter(MetaTags.ReportRunToken);
export const getSearchSettings = makeGetter(MetaTags.SearchSettings);
export const getSecretToken = makeGetter(MetaTags.SecretToken);
export const getTrialBanner = makeGetter(MetaTags.TrialBanner);
export const getWebappRevision = makeGetter(MetaTags.WebappRevision);
export const getPerformanceReport = makeGetter(MetaTags.PerformanceReport);
export const getWorkspaceIsIntegrated = makeGetter(MetaTags.WorkspaceIsIntegrated);

export function clearCache() {
  metaCache = {};
}
